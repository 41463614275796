<template>
    <div class="edit_section"  v-if="selectedForm.opt_in">
        <div class="setting_wpr" v-if="selectedForm.submit_with_member_id">
            <h3 class="border-bottom py-3">Please Enter Your Member ID</h3>
            <div class="section_item video_wpr p-0 mb-5" v-if="selectedForm.has_optin_video">
                <div class="video_wpr" v-if="((selectedForm.optin_video_type == 1 && selectedForm.optin_video_embed) || (selectedForm.optin_video_type == 2 && selectedForm.optin_video_url))">
                    <div v-if="selectedForm.optin_video_type == 2" v-html="parseEmbedCode(selectedForm.optin_video_url)"></div>
                    <div v-else v-html="selectedForm.optin_video_embed"></div>
                </div>
                <img v-else-if="selectedForm.optin_video_type == 3 && selectedForm.optin_file" :src="selectedForm.optin_file" />
                <img v-else :src="require('@/assets/images/thumb/video-placeholder.svg')">
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Member ID</label>
                    <div class="field_wpr m-0" :class="{'has-error' : errors.member_id}">
                        <Field autocomplete="off" type="text" name="member_id" v-model="form.member_id" placeholder="ex: JHON-124" rules="required" label="member id" />
                    </div>
                    <ErrorMessage name="member_id" class="text-danger" />
                </div>
            </div>
        </div>
        <div v-else class="setting_wpr pb-2">
            <h3 class="border-bottom py-3">{{ selectedForm.optin_headline ? selectedForm.optin_headline : 'Please fill all the fields below to complete the process.' }}</h3>
            <div class="section_item video_wpr p-0 mb-5" v-if="selectedForm.has_optin_video">
                <div class="video_wpr" v-if="((selectedForm.optin_video_type == 1 && selectedForm.optin_video_embed) || (selectedForm.optin_video_type == 2 && selectedForm.optin_video_url))">
                    <div v-if="selectedForm.optin_video_type == 2" v-html="parseEmbedCode(selectedForm.optin_video_url)"></div>
                    <div v-else v-html="selectedForm.optin_video_embed"></div>
                </div>
                <img v-else-if="selectedForm.optin_video_type == 3 && selectedForm.optin_file" :src="selectedForm.optin_file" />
                <img v-else :src="require('@/assets/images/thumb/video-placeholder.svg')">
            </div>
            <div class="form_grp" v-if="fields.includes('fname') || fields.includes('lname')">
                <div class="group_item" v-if="fields.includes('fname')">
                    <label class="input_label">First Name:</label>
                    <div class="field_wpr m-0"  :class="{ 'has-error': errors.first_name }">
                        <Field autocomplete="off" type="text" name="first_name" v-model="form.first_name" placeholder="ex: John" label="first name" rules="required" />
                    </div>
                    <ErrorMessage name="first_name" class="text-danger" />
                </div>
                <div class="group_item" v-if="fields.includes('lname')">
                    <label class="input_label">Last Name:</label>
                    <div class="field_wpr m-0"  :class="{ 'has-error': errors.last_name }">
                        <Field autocomplete="off" type="text" name="last_name" v-model="form.last_name" placeholder="ex: doe" label="last name" rules="required" />
                    </div>
                    <ErrorMessage name="last_name" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">E-mail</label>
                    <div class="field_wpr has_prefix m-0"  :class="{ 'has-error': errors.email }">
                        <Field autocomplete="off" type="text" name="email" v-model="form.email" placeholder="ex: johndoe@awesomecompany.com" rules="required|email" />
                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                    </div>
                    <ErrorMessage name="email" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-if="fields.includes('address')">
                <div class="group_item">
                    <label class="input_label">Address:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.address }">
                        <Field autocomplete="off" type="text" name="address" v-model="form.address" placeholder="ex: 163rd Street Washington, DC 20" rules="required" />
                    </div>
                    <ErrorMessage name="address" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-if="fields.includes('phone') || fields.includes('city')">
                <div class="group_item" v-if="fields.includes('phone')">
                    <label class="input_label">Phone Number:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.phone }">
                        <Field autocomplete="off" type="text" name="phone" v-model="form.phone" placeholder="123456789" label="contact number" rules="required|numeric|min:8|max:12" />
                    </div>
                    <ErrorMessage name="phone" class="text-danger" />
                </div>
                <div class="group_item" v-if="fields.includes('city')">
                    <label class="input_label" >City:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.city }">
                        <Field autocomplete="off" type="text" name="city" v-model="form.city" placeholder="ex: Washington DC" rules="required" />
                    </div>
                    <ErrorMessage name="city" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-if="fields.includes('state') || fields.includes('zip')">
                <div class="group_item" v-if="fields.includes('state')">
                    <label class="input_label">State:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.state }">
                        <Field autocomplete="off" type="text" name="state" v-model="form.state" placeholder="ex: Washington" rules="required" />
                    </div>
                    <ErrorMessage name="state" class="text-danger" />
                </div>
                <div class="group_item" v-if="fields.includes('zip')">
                    <label class="input_label">Zip Code:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.zip }">
                        <Field autocomplete="off" type="text" name="zip" v-model="form.zip" placeholder="ex: 56877" label="zip code" rules="required" />
                    </div>
                    <ErrorMessage name="zip" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-if="fields.includes('country') || fields.includes('dob')">
                <div class="group_item" v-if="fields.includes('country')">
                    <label class="input_label">Country:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.country }">
                        <Field autocomplete="off" name="country" v-model="form.country" rules="required">
                            <multiselect
                                v-model="form.country"
                                :options="countries"
                                value-prop="code"
                                label="country"
                                :searchable="true"
                                placeholder="Select country"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="country" class="text-danger" />
                </div>
                <div class="group_item" v-if="fields.includes('dob')">
                    <label class="input_label">Date of Birth:</label>
                    <div class="field_wpr m-0" :class="{ 'has-error': errors.dob }">
                        <Field autocomplete="off" name="dob" v-model="form.dob" rules="required" label="date of birth">
                            <datepicker v-model="form.dob" autoApply placeholder=" Date of birth" format="MM/dd/yyyy"></datepicker>
                        </Field>
                    </div>
                    <ErrorMessage name="dob" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-if="customFields.length">
                <template  v-for="(customField, c) of customFields" :key="c">
                    <div class="group_item" :class="{'mt-3' : c > 1 }" v-if="fields.includes(`${customField.name}`)">
                        <div v-if="fields.includes(`${customField.name}`)">
                            <label class="input_label">{{ customField.name }}</label>
                            <div class="field_wpr m-0" :class="{ 'has-error': errors[`custom_field[${customField.id}]`] }">
                                <Field autocomplete="off" type="text" :name="`custom_field[${customField.id}]`" v-model="form[customField.name]" rules="required" :label="customField.name.toLowerCase()"/>
                            </div>
                        </div>
                        <ErrorMessage :name="`custom_field[${customField.id}]`" class="text-danger" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoParser from '@/utils/VideoParser'

    import { Field, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        data () {
            return {
                form: {
                    member_id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: [],
                    dob: '',
                },
                fields: [],
                selectedForm: {},
                customFields: [],
            }
        },

        props: {
            errors: [Object, Array],
            activeForm: {
                type: Object,
                default: () => {}
            },
        },

        components: {
            Field,
            ErrorMessage,
        },

        watch: {
            activeForm: {
                handler (form) {
                    const vm  = this;

                    vm.fields = form.add_ons;
                    vm.selectedForm = form;
                },
                deep: true
            },

            selectedFormOrignal (selectedForm) {
                const vm = this;

                vm.selectedForm = selectedForm;
            },

            customFieldsAll (fields) {
                Object.values(fields).forEach((field) => {
                    vm.customFields.push(field);
                });
            }
        },

        computed: mapState({
            selectedFormOrignal: state => state.formModule.selectedForm,
            countries: state => state.commonModule.countries,
            user: state => state.authModule.user,
            customFieldsAll: state => state.contactModule.customFieldsAll,
        }),

        mounted () {
            const vm  = this;

            vm.selectedForm = vm.selectedFormOrignal;

            vm.fields = vm.selectedForm.add_ons.split(',');

            if (vm.activeForm && vm.activeForm.id) {
                vm.fields = vm.activeForm.add_ons;
            }

            vm.resetForm();

            if (!vm.customFieldsAll.length) {
                vm.getCustomFields({ active: 1, type: 'all' });
            }

            Object.values(vm.customFieldsAll).forEach((field) => {
                vm.customFields.push(field);
            });
        },

        methods: {
            ...mapActions({
                getCustomFields: 'contactModule/getCustomFields',
            }),

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    first_name: vm.user.fname,
                    last_name: vm.user.lname,
                    email: vm.user.email,
                    phone: vm.user.mobileno,
                    address: vm.user.address,
                    city: vm.user.city,
                    state: vm.user.state,
                    zip: vm.user.zip,
                    country: vm.user.country,
                    dob: '',
                };
            },
        },
    }
</script>

<style scoped>
    .dashboard_content h3{
        font-size: 22px;
        line-height: 28px;
    }
    .edit_section{
        margin-bottom: 40px;
    }
    .area_body.tab .dashboard_content .edit_section h3{
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
    }
    .tab .dashboard_content .faq_list h5, .tab .dashboard_content .form_grp .input_label {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 3px;
    }
    .tab .dashboard_content .field_wpr input, .tab .form_grp .multiselect, .tab .form_grp :deep(input) {
        min-height: 35px;
        height: 35px;
        font-size: 13px;
        padding: 5px 10px;
    }
    .tab .dashboard_content .form_grp{
        padding: 10px 0;
    }
    .tab .field_wpr.has_prefix {
        padding-left: 45px;
    }
    .tab .field_wpr.has_prefix .prefix {
        width: 45px;
    }
    .tab .field_wpr.has_prefix .prefix i {
        font-size: 17px;
    }

    .cell .dashboard_content h3 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .cell .dashboard_content .edit_section h3{
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 15px;
    }
    .cell .dashboard_content .form_grp{
        padding: 15px 0;
    }
    .cell .dashboard_content .faq_list h5, .cell .dashboard_content .form_grp .input_label {
        font-size: 13px;
        line-height: 17px;
    }
    .cell .dashboard_content .field_wpr input, .cell .form_grp .multiselect, .cell .form_grp :deep(input) {
        min-height: 40px;
        height: 40px;
        font-size: 13px;
        padding: 0 15px;
    }
    .cell .form_grp :deep(.multiselect-caret){
        margin-right: 0;
    }
    .cell .field_wpr.has_prefix {
        padding-left: 40px;
    }

    .cell .field_wpr.has_prefix .prefix {
        width: 40px;
    }

    .cell .field_wpr.has_prefix .prefix i {
        font-size: 15px;
    }
    .cell .edit_section .setting_wpr .form_grp {
        gap: 30px;
    }
    .cell .setting_wpr .group_item{
        flex: 0 0 100%;
    }
</style>
